.videos-container {
    width: 100%;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.videos-title {
    font-family: 'Playfair+Display';
    font-size: 3em;
    font-weight: 300;
    padding: 30px 0;
    color: $main-color;
    margin-top: 80px;
    margin-bottom: 30px;
}

.videos-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.video-count {
    font-family: 'Playfair+Display';
    font-weight: 300;
    font-size: 3em;
    text-align: center;
    color: $main-color;
}

.video-item-box {
    // width: 19%;

    padding: 10px;
}

.video-pic {
    width: 100%;
    object-fit: contain;
    cursor: pointer;
}

.video-title {
    text-align: center;
    margin-top: 10px;
    opacity: 0.6;
}

.video-player-container {
    position: absolute;
    top: 30%;
    width: auto;
    z-index: 999;
    display: flex;
    justify-content: center;
    background-color: $main-color;
    padding: 20px;
}

.video-player-video {
    width: 500px;
    object-fit: contain;
}

@media (max-width: 768px) {
    .videos-title {
        font-size: 1.8em;
    }
    .videos-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
    .video-count {
        font-size: 2em;
    }
    .video-item-box {
        width: 60%;
        padding: 5px;
        margin-bottom: 20px;
    }
}
