.toolbar {
    position: fixed;
    width: 100%;
    background: transparent;
    height: 150px;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 40px 10px;
    transition: 0.5s;
    display: flex;
    justify-content: center;

    .toolbar-container {
        max-width: 1200px;
        width: 100%;

        .toolbar__navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 0 1rem;
        }

        .toolbar__logo {
            margin-left: 1rem;

            a {
                color: white;
                text-decoration: none;
                font-size: 1.5rem;
            }
        }

        .toolbar_navigation-items {
            width: 100%;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                align-items: center;
                margin-left: 100px;
                margin-right: 100px;
            }

            li {
                padding: 0 0.5rem;
                position: relative;
            }

            a {
                color: white;
                text-decoration: none;

                &:hover,
                &:active {
                    color: orange;
                }
            }
        }
    }
}

.toolbar-header-button {
    border: none;
    width: 150px;
    height: 50px;
    color: white;
    background: $yellow-color;
    box-shadow: 0px 0px 40px #00000033;
    opacity: 1;
    cursor: pointer;
}

.toolbar.active {
    background: $main-color;
    height: 80px;
    padding: 20px 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

    a {
        color: white;
    }
}

@media (max-width: 768px) {
    .toolbar_navigation-items {
        display: none;
    }

    .toolbar,
    .toolbar.active {
        padding: 0;
    }
    .toolbar__navigation {
        display: flex;
        flex-direction: row-reverse;
    }
}

@media (min-width: 769px) {
    .toolbar_navigation-items {
        display: block;
    }
    .toolbar__toggle-button {
        display: none;
    }

    .toolbar__logo {
        margin-left: 0;
    }
}
