.knowmore-container {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.knowmore-header {
    font-family: 'Playfair+Display';
    font-size: 3em;
    padding: 30px 0;
}

.knowmore-select-box {
    width: 80%;
    display: flex;
    justify-content: space-between;
    border: 2px solid $purple-color;
    padding: 5px;
}

.knowmore-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
}

.knowmore-icon {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: $yellow-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.down-arrow {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}

.down-arrow::after {
    display: inline-block;
    content: ' ';
    height: 10px;
    width: 10px;
    border-width: 0 2px 2px 0;
    border-color: #ffffff;
    border-style: solid;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    transform-origin: center;
    transition: transform 0.3s;
    position: absolute;
    top: 40%;
    right: 15px;
    margin-top: -5px;
}

.down-arrow.active::after {
    margin-top: 0px;
    transform-origin: center;
    transform: rotate(-135deg);
    transition: transform 0.3s;
}

.knowmore-detail {
    padding: 10px 0;
}

@media (max-width: 768px) {
    .knowmore-container {
        margin-top: 50px;
    }
    .knowmore-header {
        font-size: 2em;
    }
}
