@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&family=Roboto:wght@300;400;500;700&display=swap');

$main-color: #2d3854;
$purple-color: #424c65;
$yellow-color: #e29d20;
$white-color: #ffffff;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: 'Roboto';
    background-color: $main-color;
    color: $white-color;
}

@import './toolbar.scss';
@import './sideDrawer.scss';
@import './backDrop.scss';
@import './home.scss';
@import './certifications.scss';
@import './aboutus.scss';
@import './layout.scss';
@import './videos.scss';
@import './history.scss';
@import './factory.scss';
@import './knowmore.scss';
@import './getInTouch.scss';
@import './footer.scss';
