.layout-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.layout-box {
    width: 100%;
    max-width: 1200px;
}
