.section-one {
    position: relative;
    min-height: 600px;
}
.home-banner {
    position: relative;
    height: 100vh;
    min-height: 600px;
    background: url(https://res.cloudinary.com/dcmxioazd/image/upload/v1657700479/web/3_exvbey.webp);
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 0%, $main-color 90%);
}

.home-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.home-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 100px;
    z-index: 10;
}

.home-logo {
    width: 500px;
    height: 300px;
    object-fit: contain;
}

.home-span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid #fff;
    border-radius: 50px;
    box-sizing: border-box;
}
.home-span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sdb10 2s infinite;
    animation: sdb10 2s infinite;
    box-sizing: border-box;
}
@-webkit-keyframes sdb10 {
    0% {
        -webkit-transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        -webkit-transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@keyframes sdb10 {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        transform: translate(0, 20px);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .home-logo {
        width: 300px;
        height: 300px;
        object-fit: contain;
    }
}
