.side-drawer {
    height: 100%;
    background: $purple-color;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    padding: 50px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;

    ul {
        height: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    li {
        margin: 1rem 0;
    }

    a {
        text-decoration: none;
        font-size: 1.2rem;

        &:hover,
        &:active {
            color: orange;
        }
    }
}

.side-drawer.open {
    transform: translateX(0);
}

@media (min-width: 769px) {
    .side-drawer {
        display: none;
    }
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}

.toggle-button-line {
    width: 30px;
    height: 2px;
    background: white;
}
