.footer-container {
    padding-top: 100px;
}

.footer-top-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer-item {
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: content;
    padding: 20px;
}

.footer-item-title {
    font-family: 'Playfair+Display';
    font-size: 1.6em;
    padding: 30px 0;
}

.footer-item-box {
    display: flex;
    align-items: center;
}

.footer-item-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white-color;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-item-detail {
    width: 80%;
    opacity: 0.5;
}

.footer-icon-style {
    color: black;
    font-size: 14px;
}

.footer-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 20px;
}
.footer-bottom-left {
    width: 300px;
    font-size: 14px;
}
.footer-bottom-center {
    width: 40%;
    font-family: 'Playfair+Display';
    font-size: 1.5em;
    display: flex;
    justify-content: center;
}

.footer-bottom-right {
    width: 300px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.footer-line {
    width: 1px;
    height: 20px;
    background-color: $white-color;
    margin: 0 10px;
}

@media (max-width: 768px) {
    .footer-container {
        padding-top: 50px;
    }

    .footer-top-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .footer-item {
        width: 80%;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
    }
    .footer-bottom-left {
        width: 100%;
        font-size: 14px;
        text-align: center;
    }
    .footer-bottom-center {
        width: 100%;
        font-family: 'Playfair+Display';
        font-size: 1.5em;
        display: flex;
        justify-content: center;
        margin: 20px 0;
    }

    .footer-bottom-right {
        width: 80%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
    }
}
