.factory-container {
    min-height: 600px;
}

.factory-header {
    width: 100%;
    font-family: 'Playfair+Display';
    font-size: 3em;
    font-weight: 300;
    padding: 30px 0;
    text-align: center;
    margin-top: 30px;
}
.factory-pic-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 50px;
}

.factory-big-pic {
    width: 60%;
}

.factory-big-pic-pic {
    width: 100%;
    height: 600px;
    object-fit: contain;
    transition: all 1s ease-in-out;
}

.factory-small-pic {
    width: 38%;
    height: 600px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #687084;
        box-shadow: inset 0 0 5px grey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $yellow-color;
    }
}

.factory-pic-item {
    width: 45%;
    margin: 10px;
}
.factory-pic-item-active {
    padding: 10px;
    border: 5px solid $yellow-color;
}

.factory-pic-pic {
    width: 100%;
    object-fit: contain;
    cursor: pointer;
}

@media (max-width: 768px) {
    .factory-header {
        font-size: 2em;
    }

    .factory-pic-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    .factory-big-pic {
        width: 100%;
        padding: 10px;
    }

    .factory-big-pic-pic {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .factory-small-pic {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: scroll;
        /* width */
        &::-webkit-scrollbar {
            width: 5px;
            height: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #687084;
            box-shadow: inset 0 0 5px grey;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $yellow-color;
        }
    }

    .factory-pic-item {
        margin: 10px;
    }

    .factory-pic-pic {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    .factory-pic-item-active {
        padding: 5px;
        border: 2px solid $yellow-color;
    }
}
