.cer-container {
    min-height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
}

.cer-header {
    font-family: 'Playfair+Display';
    font-size: 2em;
    font-weight: 300;
    padding: 30px 0;
}

.cer-item-box {
    width: 19%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cer-box {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.cer-pic {
    width: 100px;
    height: 90px;
    object-fit: contain;
    margin: 30px 0;
}

.cer-title {
    height: 80px;
    text-align: center;
    width: 200px;
    opacity: 0.6;
}

.cet-button {
    width: 150px;
    height: 40px;
    box-shadow: 0px 0px 40px #00000033;
    border: 1px solid #ffffff;
    color: white;
    opacity: 0.6;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .cer-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .cer-item-box {
        width: 80%;
        margin-top: 30px;
    }
}
