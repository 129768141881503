.history-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.history-title {
    font-family: 'Playfair+Display';
    font-size: 3em;
    font-weight: 300;
    padding: 30px 0;
}
.history-pic-box {
    margin-top: 50px;
    margin-bottom: 100px;
    width: 60%;
}
.history-pic {
    width: 100%;
    object-fit: contain;
}

@media (max-width: 768px) {
    .history-title {
        font-size: 2em;
    }
    .history-pic-box {
        width: 80%;
    }
}
