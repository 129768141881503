.aboutus-container {
    margin-top: 100px;
    min-height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutus-pic {
    position: relative;
    width: 500px;
    height: 550px;
}

.aboutus-pic1 {
    position: absolute;
    top: 100px;
    left: 0;
    width: 60%;
    object-fit: contain;
    z-index: 1;
}

.aboutus-pic2 {
    position: absolute;
    top: 0;
    left: 40%;
    width: 60%;
    object-fit: contain;
}

.aboutus-logo {
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.aboutus-title {
    font-family: 'Playfair+Display';
    font-size: 3em;
    font-weight: 300;
    padding: 30px 0;
}

.aboutus-item-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}
.aboutus-item-text {
    width: 48%;
}
.aboutus-item-line {
    display: block;
    width: 1px;
    background-color: $white-color;
    opacity: 0.5;
}

.aboutus-item-ul {
    margin-left: 50px;
    padding: 20px;
}

.aboutusitem-container {
    padding: 10px 0;
    margin-bottom: 30px;
}

.aboutusitem-box {
    width: 100%;
    display: flex;
}

.aboutusitem-icon {
    margin-right: 15px;
}

.aboutusitem-icon-icon {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.aboutusitem-title {
    opacity: 0.7;
}

.aboutusitem-sub-text {
    padding-top: 10px;
    opacity: 0.4;
}
@media (max-width: 768px) {
    .aboutus-pic {
        position: relative;
        width: 300px;
        height: 400px;
    }

    .aboutus-item-ul {
        margin-left: 0px;
        padding: 0px;
    }

    .aboutus-item-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .aboutus-item-text {
        width: 80%;
    }
    .aboutus-item-line {
        display: none;
    }
}
