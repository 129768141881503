.touch-container {
    background-color: $yellow-color;
    min-height: 400px;
    display: flex;
    margin-top: 20px;
}

.touch-left {
    width: 65%;
    padding: 50px;
}

.touch-right {
    width: 35%;
    background-image: url('https://res.cloudinary.com/dcmxioazd/image/upload/v1657701477/web/logo3_u39muo.svg');
    background-repeat: none;
    background-position: center;
    background-size: cover;
}

.touch-header {
    font-family: 'Playfair+Display';
    font-size: 2em;
    padding: 30px 0;
}

.touch-input-box {
    display: flex;
    justify-content: space-between;
}

.touch-input-item {
    width: 48%;
}

.touch-input-style {
    border: 1px solid #ffffff;
    background: transparent;
    width: 100%;
    height: 40px;
    padding: 10px;
    color: $white-color;
    outline-style: none;
    margin: 10px 0;
    &::placeholder {
        color: #ffffff;
        opacity: 0.6;
    }
}

.touch-textarea-style {
    width: 100%;
    height: 100px;
    margin: 10px 0;
    border: 1px solid #ffffff;
    background: transparent;
    padding: 10px;
    color: $white-color;
    outline-style: none;
    &::placeholder {
        color: #ffffff;
        opacity: 0.6;
    }
}

.touch-button {
    width: 100%;
    height: 40px;
    margin: 5px 0;
    border: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .touch-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
    }

    .touch-left {
        width: 100%;
    }

    .touch-right {
        width: 100%;
        padding-bottom: 100%;
    }

    .touch-input-box {
        display: flex;
        flex-direction: column;
    }

    .touch-input-item {
        width: 100%;
    }
}
